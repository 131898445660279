import styled from 'styled-components';

const widths = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};



export const devices = {
  mobileS: `(min-width: ${widths.mobileS})`,
  mobileM: `(min-width: ${widths.mobileM})`,
  mobileL: `(min-width: ${widths.mobileL})`,
  tablet: `(min-width: ${widths.tablet})`,
  laptop: `(min-width: ${widths.laptop})`,
  laptopL: `(min-width: ${widths.laptopL})`,
  desktop: `(min-width: ${widths.desktop})`,
};

export const heights = {
  small: `(min-height: 400px)`,
  medium: `(min-height: 650px)`,
  large: `(min-height: 850px)`,
}

export const Wrapper = styled.div`
  background-color: #E5E5E5;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  min-height: 89px;
  height: 10vh;
  width: 100%;

  .faq-label {
    // background-color: red;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    color: #757575;

    margin-right: 2.5%; 
    margin-bottom: 0.4%;


    cursor: pointer;
  }
`;

export const WalletButton = styled.button`
  margin-right: 2.5%;  
  margin-bottom: 0.5%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(242.82deg, #25F2FF -27.78%, rgba(0, 0, 0, 0.62) 130.94%);
  border-radius: 6vh;//20px;

  font-family: SFProDisplay-Bold;
  color: white;

  cursor: pointer;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
  font-size: calc(14px + (16 - 14) * ((100vw - 800px) / (1800 - 800)));
`;

export const ConnectedWalletButton = styled.div`
  height: 46.54%;
  width: 9.64vw;
  margin-right: 2.5%;  
  margin-bottom: 0.5%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;

  border: 2px #38A6AC solid;
  border-image-source: #38A6AC; //linear-gradient(242.82deg, #25F2FF -27.78%, rgba(0, 0, 0, 0.62) 130.94%);

  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;

  background: -webkit-linear-gradient(#25F2FF, rgba(0, 0, 0, 0.62));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  

  cursor: pointer;
`