import React, { useState, useEffect } from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//Components
import Header from './components/Header/Header';

// Screens
import CommunityFeedPage from './endgame-screens/CommunityFeedPage/CommunityFeedPage';

// API
import { auth_with_wallet } from './api/endgame';


import useStore from './store';

// Types
export type ContentSubmission = {
  id: string;
  text?: string;
  image_url?: string;
  user_votes_allocated: number;
}

export type User = {
  id: string;
  name?: string;
  profile_image_url?: string;
  wallet_address?: string;
  content_submissions: ContentSubmission[];
  endorsed_submissions: ContentSubmission[];
  votes: { [submission_id: string]: number }
}

interface Props { }



const Game: React.FC = () => {

  const [connectedWallet, setConnectedWallet] = useState<string>('');

  const { setAccount, setUser } = useStore((state) => state.actions)

  const checkIfWalletIsConnected = async () => {
    /*
    * First make sure we have access to window.ethereum
    */
    try {
      const { ethereum } = window;

      if (!ethereum) {
        console.log("Make sure you have metamask!");
        return;
      } else {
        console.log("We have the ethereum object", ethereum);
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' });

      if (accounts.length !== 0) {
        const account = accounts[0];
        console.log("GAME Found an authorized account:", account);
        setConnectedWallet(account)
      } else {
        console.log("No authorized account found");
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Connect wallet method
   */
  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      console.log("Connected", accounts[0]);

      setConnectedWallet(accounts[0]);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected();
  }, [])

  useEffect(() => {
    async function auth() {
      await auth_with_wallet(connectedWallet, setUser);
    }

    setAccount(connectedWallet);
    auth();
  }, [connectedWallet, setAccount, setUser])



  return (
    <BrowserRouter>
      <nav>
        <Header onClickConnectWallet={connectWallet} />
      </nav>
      <Routes>
        <Route path="/" element={<CommunityFeedPage />} />
      </Routes>
    </BrowserRouter>
  );
}


export default Game;