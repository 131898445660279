import styled from 'styled-components';

export const Wrapper = styled.div`
  
  position: fixed;

  height: auto;
  // min-height: 25%:
  width: 30%;
  // min-width: 30vw;
  
  top: 15%;
  left: 35vw;

  border-radius: 1.8vw;  
  background-color: pink;

  display: flex;
  flex-direction: column;
  align-items: center;

  
  // align-self: center;

  overflow-y: auto;
  z-index: 100;
  
  img {
    height: 3vh;
    width 3vh;
    align-self: flex-end;

    margin-top: 2.3%;
    margin-right: 2.75%;
  
    cursor: pointer;
  }

  textarea {
    margin-top: 1%;
    resize: none; 
    // display: block;
    hidden: true;

    background-color: transparent;
    border: none;

    min-height: 70%; 
    width: 80%;
  }

  label {
    color: black;
  }

  textarea:hover, 
  input:hover, 
  textarea:active, 
  input:active, 
  textarea:focus, 
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active
  {
      outline:0px !important;
      -webkit-appearance:none;
      box-shadow: none !important;
  }

  .button-row {
    min-height: 36px;
    height: 4vh; 
    width: 80%;

    margin-top: 5%;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 2%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background-color: red;
  }

  .attach-image {
    height: 100%; 
    // width: 25%;
    // margin-left: 3%;
    border: none;
    background-color: grey;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0 10px;
    border-radius: 40vw;

    cursor: pointer;
  }

  .submit-post-button {
    height: auto; 
    width: 25%;
    background-color: grey;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    border-radius: 40vw;

    cursor: pointer;
  }
`;