import { Dispatch, SetStateAction } from 'react';

// Types
import { User, ContentSubmission } from '../Game';

const baseUrl = "https://p9pty7sx01.execute-api.us-east-1.amazonaws.com/Prod/v1";


export async function generalFormFetch(endpoint: string, get = false, data: any = null) {

  return fetch(baseUrl + endpoint, {
    method: get ? 'GET' : 'POST',
    headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
    body: get ? null : JSON.stringify(data),
    mode: 'cors'
  })
    .then(response => response.json())
    .then(data => { return data; })
}

export const auth_with_wallet = async (wallet_address: string, setUser: (user: User) => void) => {
  const endpoint = "/user";

  var data = {
    'wallet_address': wallet_address
  };

  if (wallet_address !== "") {
    generalFormFetch(endpoint, false, data).then(res => {
      var content_submissions = res.content_submissions === undefined ? [] : res.content_submissions;
      var endorsed_submissions = res.endorsed_submissions === undefined ? [] : res.endorsed_submissions;
      var votes = res.votes === undefined ? {} : res.votes;

      var user_id = res.user_id;
      var user: User = {
        id: user_id,
        wallet_address: wallet_address,
        content_submissions: content_submissions,
        endorsed_submissions: endorsed_submissions,
        votes: votes
      };

      setUser(user);
    });
  }
}

export const submit_content_idea = async (
  user_id: string,
  submission: ContentSubmission,
  setContentSubmissions: Dispatch<SetStateAction<{
    [id: string]: ContentSubmission;
  }>>
) => {
  const endpoint = "/post";

  var data = {
    user_id: user_id,
    content_text: "",
    image: ""
  };

  // add text content to payload
  if (submission.text) {
    data["content_text"] = submission.text;
  }

  // add image encoding to payload 
  if (submission.image_url) {
    data["image"] = submission.image_url;
  }

  generalFormFetch(endpoint, false, data).then(res => {
    submission.id = res.post_id;

    setContentSubmissions((prevState) => ({
      ...prevState,
      [res.post_id]: submission
    }));
  });
}

export const register_votes_on_server = async (
  user_id: string,
  submission: ContentSubmission,
  voting_power: number,
  setContentSubmissions: Dispatch<SetStateAction<{
    [id: string]: ContentSubmission;
  }>>
) => {
  const endpoint = '/vote?';

  var data = {
    user_id: user_id,
    post_id: submission.id,
    voting_power: voting_power
  }

  generalFormFetch(endpoint, false, data).then(res => {

    submission['user_votes_allocated'] += voting_power;
    setContentSubmissions((prevState) => ({
      ...prevState,
      [submission.id]: submission
    }));
  });
}

export const get_all_submissions = async (
  user_id: string,
  setContentSubmissions: Dispatch<SetStateAction<{
    [id: string]: ContentSubmission;
  }>>) => {
  const endpoint = "/posts?user_id=" + user_id;

  generalFormFetch(endpoint, true).then(res => {
    console.log("GET ALL POSTS")
    console.log(res)
    console.log()

    var submissions: { [id: string]: ContentSubmission } = {};
    res.posts.forEach((item: any) => {
      console.log(item)
      var submission: ContentSubmission = {
        id: item.id,
        text: item.text ? item.text : undefined,
        image_url: item.image_url ? item.image_url : undefined,
        user_votes_allocated: item.voting_power
      }

      submissions[item.id] = submission;
    })

    setContentSubmissions(submissions);
  })
}

export const get_user_votes_remaining = async (
  user_id: string,
  setVotesRemaining: Dispatch<SetStateAction<number>>,
  setEpochEndTime: Dispatch<SetStateAction<string>>
) => {
  const endpoint = "/votes?user_id=" + user_id;

  generalFormFetch(endpoint, true).then(res => {
    var votes_remaining_in_curr_epoch = res.total_votes_available_today;
    var votes_used = res.votes_used_today;

    setVotesRemaining(votes_remaining_in_curr_epoch - votes_used);
    setEpochEndTime(res.epoch_end_time);
  })

}