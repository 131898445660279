import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Game from './Game';

import './fonts/Courier-Regular.ttf';
import './fonts/SFProDisplay-Regular.ttf';
import './fonts/SFProDisplay-SemiBold.ttf';
import './fonts/SFProDisplay-Bold.ttf';

ReactDOM.render(
  <Game />
  , document.getElementById('root')
);

