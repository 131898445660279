import styled from 'styled-components';

export const Wrapper = styled.div`
  // background-color: yellow;
  // height: 100vh;
  // width: 100vw;
  // flex: display;
  // justify-content: center;
  height: 100%;
  width: 65vw;
  max-width: 100vw;

  .grid-container {
    // background: red;
    height: auto;
    min-height: 95%;
    // min-width: 50vw;
    width:  100%;
    // max-width: 70%;

    margin-top: 6vh;
    // margin-left: 10%;

    justify-content: center;
    padding: 0;
  }

  .text-content-box {
    background-color: #FAFAFA;
    height: auto;
    min-height: 3vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding-top: .4vh;
    padding-bottom: 2vh;
    border-radius: 8px 8px 0 0;
    // overflow: wrap;

    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #7F7F7F;
  }

  .text-content {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    text-align: initial;
    height: auto;
  }

  img {
    object-fit: contain;
    border-radius: 0 0 8px 8px;
  }

  .post-info-section {
    // background-color: red;
    margin-top: 9px;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 9px;

    display: flex;
    flex-direction: column;
  }

  .post-info-section-title {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: right;

    color: #7F7F7F;
  }

  .post-info-section-content {
    margin-top: 4px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }


`;