import create from 'zustand'

const initialState = {
    account: undefined,
    user: undefined,
}

// Types
interface ContentSubmission {
    id: string;
    text?: string;
    image_url?: string;
    // image?: File,
    user_votes_allocated: number;
}
  
interface User {
    id: string;
    name?: string;
    profile_image_url?: string;
    wallet_address?: string;
    content_submissions: ContentSubmission[];
    endorsed_submissions: ContentSubmission[];
    votes: { [submission_id: string]: number }
}

interface Actions {
    setAccount: (account: string) => void;
    setUser: (user: User) => void;
    unlinkAccount: () => void;
}

interface GameState {
    account: string | undefined;
    user: User | undefined;
    actions: Actions;
}

const useStore = create<GameState>((set) => ({
    ...initialState,
    actions: {
        setAccount: (account: string) => set(() => {
            return ({ account: account })
        }),
        setUser: (user: User) => set(() => {
            return ({ user: user })
        }),
        unlinkAccount: () => set({
            ...initialState
        })
    }
}))

export default useStore;