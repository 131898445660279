
export const calculateTimeLeft = (datetime: string) => {

  let epochEndDate = new Date(datetime);

  const difference = +epochEndDate - +new Date();

  var timeLeft: {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };

    return timeLeft;
  }
};

export const getTimeLeftString = (datetime: string) => {
  var timeLeft = calculateTimeLeft(datetime);

  if (timeLeft) {
    return timeLeft.hours + " hrs, " + timeLeft.minutes + " min, " + timeLeft.seconds + " seconds";
  }
  
  return "";
}