import React, { useState, useEffect } from 'react';
import useStore from '../../store';

import { Wrapper, WalletButton, ConnectedWalletButton } from './Header.styles';

export type Props = {
  // onClickConnectWallet?: (e:  React.MouseEvent) => void;
  // connectedWallet: string;
  onClickConnectWallet: () => void;
}

const Header: React.FC<Props> = ({ onClickConnectWallet }) => {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height
    };
  }

  function useWindowDimensions() {

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      // return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions()

  const connectedWallet = useStore((state) => state.account)

  const getWalletAddressPrefix = () => {
    if (connectedWallet) {
      return connectedWallet.substr(0, 8);
    }

  }

  const isVerticalView = width < 800;

  return (
    <Wrapper>
      <div className="faq-label"
        style={{
          fontSize: isVerticalView ? "22px" : "18px",
          marginRight: isVerticalView ? "4%" : "2.5%"
        }}>FAQ</div>

      {!isVerticalView ? (
        <>
          {connectedWallet !== undefined && connectedWallet === "" ? (
            <div
              style={{
                marginRight: '2.5%'
              }}
            >
              <WalletButton
                onClick={onClickConnectWallet}

              >
                Connect To Wallet
              </WalletButton>
            </div>
          ) : (
            <ConnectedWalletButton>
              {getWalletAddressPrefix()}...
            </ConnectedWalletButton>
          )}
        </>
      ) : (
        <></>
      )}


    </Wrapper>
  )
}

export default Header; 