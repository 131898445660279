import React, { useState, useEffect } from "react";

// Type
import { ContentSubmission } from '../../Game';
// Components
import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack';

// Styles
import { Wrapper } from './ContentSubmissionsFeed.styles';

type Props = {
  submissions: { [id: string]: ContentSubmission }
  onSubmissionClicked: (post_id: string) => void;
}
const ContentSubmissionsFeed: React.FC<Props> = ({ submissions, onSubmissionClicked }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const getNumColumns = () => {

    if (width > 1350) {
      return 3;
    }

    else if (width > 900) {
      return 2;
    }
    return 1;
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height
    };
  }

  function useWindowDimensions() {

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      // return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions()

  const getSubmission = (item: ContentSubmission, id: number) => {

    var vote_count = item.user_votes_allocated;
    console.log("ITEm")
    console.log(item)
    console.log(item.image_url)

    return (
      <Stack key={id} >
        <div className="text-content-box">
          <div style={{ alignSelf: "flex-end", marginRight: "1%", color: "green" }}>{vote_count}{vote_count === 1 ? " vote" : " votes"}</div>
          <div className="text-content" style={{ whiteSpace: "pre-line" }}>{item.text}</div>
        </div>
        <img
          hidden={item.image_url === undefined}
          src={item.image_url}
          // srcSet={`${item.image_url.substr(5)}?w=162&auto=format&dpr=2 2x`}
          alt={"test"}
          loading="lazy"
        />
      </Stack>
    );
  }

  const getVerticalContainerStyle = () => {
    if (width < 800) {
      return {
        display: 'flex',
        marginLeft: "0",
        justifyContent: "center",
      }
    }

    return {};
  }

  const getVerticalWrapperStyle = () => {
    if (width < 800) {
      return {
        // display: 'flex',
        // marginLeft: "0",
        // justifyContent: "center",
        // width: '100vw',
        // minWidth: '100vw'
      }
    }

    return {};
  }

  return (
    <Wrapper style={getVerticalWrapperStyle()}>
      <div className="grid-container" style={getVerticalContainerStyle()}>
        <Masonry columns={getNumColumns()} spacing={2}>
          {Object.keys(submissions).map((id, index: number) => {

            var submission = submissions[id];

            return (
              <div key={index} style={{ cursor: "pointer" }} onClick={() => onSubmissionClicked(id)}>
                {getSubmission(submission, index)}
              </div>
            );
          })}
        </Masonry>
      </div>
    </Wrapper>
  )
}

export default ContentSubmissionsFeed;
