import React, { useEffect, useState } from "react";

import { submit_content_idea, register_votes_on_server, get_user_votes_remaining } from '../../api/endgame';

import { getTimeLeftString } from '../../utils/datetime_functions';

// Components
import ContentSubmissionsModal from '../../endgame-components/ContentSubmissionsModal/ContentSubmissionsModal';
import VotingSubmissionsModal from '../../endgame-components/VotingSubmissionsModal/VotingSubmissionsModal';
import ContentSubmissionsFeed from '../../endgame-components/ContentSubmissionsFeed/ContentSubmissionsFeed';

// Styles
import { Wrapper } from './CommunityFeedPage.styles';
import { ContentSubmission } from '../../Game';

import useStore from "../../store";

const CommunityFeedPage = () => {

  const user = useStore((state) => state.user);

  // const [contentSubmissions, setContentSubmission] = useState<ContentSubmission []>([]);
  const [epochEndTime, setEpochEndTime] = useState<string>('');
  const [contentSubmissions, setContentSubmissions] = useState<{ [id: string]: ContentSubmission }>({});
  const [votesRemaining, setVotesRemaining] = useState(3);
  const [isSubmittingContent, setIsSubmittingContent] = useState(false);
  const [isVoting, setIsVoting] = useState(false);
  const [clickedSubmissionId, setClickedSubmissionId] = useState<string>('');

  const walletAddress = useStore((state) => state.account);

  const [timeLeft, setTimeLeft] = useState(getTimeLeftString(epochEndTime));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeLeftString(epochEndTime));
    }, 1000);
  });

  useEffect(() => {
    console.log("HERE")
    console.log(user)
    if (user) {
      // get_all_submissions(user.id, setContentSubmissions);
      get_user_votes_remaining(user.id, setVotesRemaining, setEpochEndTime);
    }
  }, [user]);


  const onExitSubmitContentModal = () => {
    setIsSubmittingContent(false);
  }

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const submitContentIdea = async (text?: string, image_url?: string, image_file?: File) => {
    if (text || image_file) {

      var submission: ContentSubmission = {
        id: "",
        text: text,
        user_votes_allocated: 0
      }

      if (image_file) {
        var image_base64 = await toBase64(image_file);

        if (typeof image_base64 === 'string') {
          submission["image_url"] = image_base64;
        }
      }

      if (user) {
        await submit_content_idea(user.id, submission, setContentSubmissions)
      }

      setIsSubmittingContent(false);
    }
  }

  const onSubmissionClicked = (post_id: string) => {
    setClickedSubmissionId(post_id);
    setIsVoting(true);
  }

  const onExitVotingModal = () => {
    setClickedSubmissionId('')
    setIsVoting(false);
  }

  const getPostButton = () => {
    return (
      <div className="post-button" onClick={() => {
        console.log('getPostButton wallet address' + walletAddress)
        if (walletAddress) {
          setIsSubmittingContent(true)
        }
      }
      }>
        +
      </div>
    )
  }

  const submitVotes = async (submission_id: string, num_votes: number) => {

    if (num_votes > 0 && votesRemaining - num_votes >= 0) {

      var submission = contentSubmissions[submission_id];

      if (user) {
        await register_votes_on_server(user.id, submission, num_votes, setContentSubmissions);

        setIsVoting(false);
        setVotesRemaining(votesRemaining - num_votes);
      }
    }
  }

  return (
    <>
      <div style={{
        height: '5vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div style={{ marginLeft: '2%' }}>Countdown 'til next epoch: {timeLeft}</div>
        <div style={{ marginRight: '2%' }}>Votes remaining: {votesRemaining}</div>
      </div>
      <Wrapper>
        {isSubmittingContent ? <ContentSubmissionsModal onExitButtonClicked={onExitSubmitContentModal} onPostButtonClicked={submitContentIdea} /> : getPostButton()}
        {isVoting ? <VotingSubmissionsModal submission_id={clickedSubmissionId} onExitButtonClicked={onExitVotingModal} submitVotes={submitVotes} votesRemaining={votesRemaining} /> : <></>}
        <ContentSubmissionsFeed submissions={contentSubmissions} onSubmissionClicked={onSubmissionClicked} />
      </Wrapper>
    </>
  );
}

export default CommunityFeedPage;