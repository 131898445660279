import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;

  min-height: 20vh;
  height: auto;
  width: 35vw;

  top: 30%;
  left: 40%;
  

  border-radius: 1vw;

  background-color: pink;

  flex: display;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 100;

  img {
    height: 3vh;
    width 3vh;
    align-self: flex-start;

    margin-top: 2.3%;
    margin-left: 2.75%;
  
    cursor: pointer;
  }

  .vote-button {
    height: 30%;
    width: 15%;

    margin-top: 2%;
    margin-left: 3%;
    // background-color: blue;
    color: white;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    cursor: pointer;
  }

  input {
    margin-top: 3%;
    margin-left: 3%;
    resize: none; 

    // background-color: transparent;
    border: none;

    color: black;

    min-height: 40%; 
    width: 80%;
  }

  textarea:hover, 
  input:hover, 
  textarea:active, 
  input:active, 
  textarea:focus, 
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active
  {
      outline:0px !important;
      -webkit-appearance:none;
      box-shadow: none !important;
  }
`;