import React, { useState } from 'react';

import exit_button from "../../img/exit-button/exit-button.png";

// Styles
import { Wrapper } from './VotingSubmissionsModal.styles';

interface Props {
  submission_id: string;
  onExitButtonClicked: () => void;
  submitVotes: (submission_id: string, num_votes: number) => void;
  votesRemaining: number;
}
const VotingSubmissionsModal: React.FC<Props> = ({ submission_id, onExitButtonClicked, submitVotes, votesRemaining }) => {

  const [votesToAllocate, setVotesToAllocate] = useState('');

  // This function is triggered when textarea changes
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var input = event.target.value;
    if (!isNaN(parseFloat(input)) || input === "") {
      setVotesToAllocate(event.target.value);
    }

    if (parseFloat(input) > votesRemaining) {
      setVotesToAllocate('');
    }
  };

  return (
    <Wrapper>
      <img src={exit_button} alt="exit vote modal button" onClick={onExitButtonClicked} />
      <input maxLength={2} value={votesToAllocate} onChange={onInputChange} />
      <div className="vote-button" onClick={() => submitVotes(submission_id, Number(votesToAllocate))}>Vote</div>
    </Wrapper>
  );
}

export default VotingSubmissionsModal;
