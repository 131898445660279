import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  // margin: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  // background-color: black;

  .post-button {
    position: fixed;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 8vh;
    width: 8vh;
    border-radius: 50%;

    right: 3%;
    bottom: 6%;

    background-color: red;
    color: white;

    cursor: pointer;
  }
`;