import React, { useState, useEffect, useRef } from "react";

import Button from '@material-ui/core/Button'
import exit_button from "../../img/exit-button/exit-button.png";

// Styles
import { Wrapper } from './ContentSubmissionsModal.styles';

interface Props {
  onExitButtonClicked: () => void;
  onPostButtonClicked: (text?: string, image_url?: string, image?: File) => void;
}
const ContentSubmissionsModal: React.FC<Props> = ({ onExitButtonClicked, onPostButtonClicked }) => {

  const [value, setValue] = useState<string>('');

  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState('');

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // This function is triggered when textarea changes
  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  const onImageChange = async (e: any) => {
    var image_file = e.target.files[0];
    setImage(image_file);

    var url = URL.createObjectURL(image_file);
    setImageUrl(url);
  }

  return (
    <Wrapper>
      <img src={exit_button} alt="content modal exit button" onClick={onExitButtonClicked} />
      <textarea
        className="text-box"
        placeholder="Banger tweet goes here"
        maxLength={280}
        ref={textareaRef}
        rows={2}
        onChange={textAreaChange}
        value={value}
      >
        {value}
      </textarea>
      {
        <img alt="user generated content" style={{ height: 'auto', width: '80%', alignSelf: 'center', backgroundColor: 'red' }} src={imageUrl} hidden={imageUrl === ''} />
      }
      <div className="button-row">
        {imageUrl === '' ? (
          <>
            <label htmlFor="attach-image"
              style={{
                height: 'auto'
              }}
            // className="attach-image"
            >
              <input id="attach-image" style={{ display: 'none' }} type="file" accept="image/*" onChange={onImageChange} />
              <Button
                className="attach-image"
                component="span"
              >
                Attach image
              </Button>
            </label>
          </>
        ) : (
          <div style={{ backgroundColor: 'transparent' }} className="attach-image" />
        )
        }
        <div className="submit-post-button" onClick={() => onPostButtonClicked(value.toString(), imageUrl, image)}>Post</div>
      </div>

    </Wrapper>
  );

}

export default ContentSubmissionsModal;